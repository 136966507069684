import React from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import Layout from "@/feature-reskin-probuilds-net/components/Layout.jsx";
import Matchlist from "@/feature-reskin-probuilds-net/components/Matchlist.jsx";
import { FeedSection } from "@/feature-reskin-probuilds-net/Home.style.jsx";
import type { ProMatch } from "@/feature-reskin-probuilds-net/types.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function Role() {
  const { t } = useTranslation();

  return (
    <Layout
      title={t("", "Welcome to Pro Builds")}
      subtitle={t(
        "",
        "PROBUILDS.net is a quick guides tool for League of Legends players. Its primary function is to provide the League of Legends community easy access to Professional Player's builds across all regions",
      )}
    >
      <Feed />
    </Layout>
  );
}

type Matchlist = ProMatch[];

function Feed() {
  const {
    parameters: [currentRole],
  } = useRoute();
  const state = useSnapshot(readState);

  const proBuildMatchlist = state.lol.proBuildMatchlist[
    currentRole
  ] as Matchlist;

  return (
    <FeedSection>
      <Matchlist matches={proBuildMatchlist} currRole={currentRole} />
    </FeedSection>
  );
}

export default Role;

export function meta() {
  return {
    title: [
      "probuildsnet:home.meta.title",
      "ProBuilds - LoL Pro Champion Stats, Teams, Builds, Runes, Masteries, Counters and Matchups!",
    ],
    description: [
      "probuildsnet:home.meta.description",
      "ProBuilds provides League of Legends pro champion statistics, guides, builds, runes, masteries, skill orders and counters by role - including Win Rate, Ban Rate, Play Rate and much more!",
    ],
  };
}
